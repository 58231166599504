@use "./src/index";

@media (max-width: index.$small) {
    .tile {
        $size: 1.3em;
        width: $size;
        height: $size;
        font-size: 2 * $size;
    }
}

@media (min-width: index.$small) {
    .tile {
        $size: 1.5em;
        width: $size;
        height: $size;
        font-size: 2 * $size;
    }
}

.tile {
    display: inline-flex;
    text-transform: uppercase;
    color: white;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    margin: 0.07em;
    user-select: none;
    border: 0.05em solid;

    &.unknown {
        background-color: index.$black;
        border-color: index.$grey;
    }

    &.unused {
        background-color: index.$grey;
        border-color: index.$grey;
    }

    &.notInPosition {
        background-color: index.$yellow;
        border-color: index.$yellow;
    }

    &.inPosition {
        background-color: index.$green;
        border-color: index.$green;
    }

    &.empty {
        cursor: not-allowed;
    }

    &.nonEmpty {
        cursor: pointer;
    }
}