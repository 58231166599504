@use "./src/index";

.solutionsContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.solutionsBody {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.close {
    text-align: right;
}

.closeIcon {
    height: 3em;
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.solutionsTable {
    border-collapse: collapse;

    tr:nth-child(even) {
        background-color: index.$grey;
        color: index.$black;
    }

    tr:nth-child(odd) {
        background-color: index.$black;
        color: white;
    }

    td, th {
        border: 1px solid index.$grey;
        padding: 0.5em;
        text-align: center;
        min-width: 5em;
    }

    th {
        font-size: 1.5em;
    }

    td {
        text-transform: uppercase;
    }
}