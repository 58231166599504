$grey: #adadad;
$yellow: #b59f3b;
$green: #538d4e;
$black: #121213;

$small: 500px;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $black;
    display: flex;
    justify-content: center;
}