@use "src/index";

.gamePageBody {
}

.row {
}

@media (min-width: index.$small) {
  .keyboard {
    margin-top: 17em;
  }
}

@media (max-width: index.$small) {
  .keyboard {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 1em;
  }
}

.keyboard {
  text-transform: uppercase;
}